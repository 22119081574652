import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

import { clearDebits, fetchDebits } from '../../store/debits';
import { getDebitsState } from '../../store/debits/selectors';

import Page from '../../components/Page';
import DebitsTable from '../../components/DebitsTable';
import withVenue from '../../hoc/withVenue';
import shouldLoad from '../../shared/utils/shouldLoad';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    marginRight: '20px',
    width: '50%',
    flexGrow: 1,
  },
  form: {
    width: '100%',
    flexGrow: 2,
  },
  formElement: {
    paddingRight: theme.spacing(2),
  },
}));

const DebitsSection = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const debitsState = useSelector(getDebitsState);
  const { loading, data: debits, error: debitsError } = debitsState;

  useEffect(() => {
    if (shouldLoad(debitsState)) dispatch(fetchDebits());
  }, [dispatch, debitsState]);

  const isDebitsLoading = loading && !debits;

  const diplayOrders = debits?.docs || debits;
  const hasDebits = diplayOrders?.length > 0;

  return (
    <Page loading={isDebitsLoading} error={debitsError}>
      {debits && hasDebits && (
        <>
          <header className={classes.heading}>
            <Typography noWrap className={classes.title} variant="h2" component="h1">
              Charges
            </Typography>
          </header>
          <DebitsTable />
        </>
      )}
    </Page>
  );
};

export default withVenue(DebitsSection, null, clearDebits);
