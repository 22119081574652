import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Collapse, ListItemButton, List, ListItemIcon, ListItemText, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  BarChartOutlined,
  ChevronLeft,
  DashboardOutlined,
  ExpandLess,
  ExpandMore,
  FastfoodOutlined,
  LoyaltyOutlined,
  MoodOutlined,
  ReceiptLongOutlined,
  SettingsOutlined,
  StorefrontOutlined,
} from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import QrContainer from '../QrContainer';
import { ReactComponent as LockedIcon } from './locked.svg';

import withSettings from '../../hoc/withSettings';
import { getVenuesState, isBrandUp } from '../../store/venues/selectors';
import { clearVenue, clearVenueSettings, fetchVenue } from '../../store/venues';
import AutoComplete from '../AutoComplete';
import { AdminItem, AdminItemText } from './AdminListItem';
import withVenue from '../../hoc/withVenue';
import FallbackProvider from '../FallbackProvider';
import ROLES from '../../shared/constants/roles';
import cySelectors from '../../tests/cySelectors';
import Requires from '../RoleChecker';
import useSwitchVenue from '../../hooks/useSwitchVenue';
import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import useEventTracker from '../../hooks/useEventTracker';

const useStyles = makeStyles((theme) => ({
  sideNavContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexBasis: '100%',
    [theme.breakpoints.down('md')]: {
      paddingTop: 64,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 56,
    },
  },
  settingContainer: {
    marginBottom: theme.spacing(2),
  },
  venuelist: {
    maxHeight: '50vh',
    overflow: 'auto',
  },
  page: {
    overflow: 'auto',
    paddingTop: 0,
  },
  customStyle: {
    marginBottom: '1rem',
  },
  navWithoutIcon: {
    paddingLeft: theme.spacing(4),
    '& .MuiListItemIcon-root': {
      display: 'none',
    },
  },
}));

const isRouteSelected = (path) => window.location.pathname.startsWith(path);

const routeStyle = (path, altPath) => ({
  style: {
    fontWeight: isRouteSelected(path) || isRouteSelected(altPath) ? 600 : 400,
  },
});

const isSectionOpen = (paths = []) => paths.some((path) => isRouteSelected(path));

const Item = ({
  path,
  title,
  icon,
  open,
  handleOpen,
  isBrandup,
  role = 'staff',
  notbrandup = false,
  notDeliveryOnly = false,
  notMultivendor = false,
  hasStyle,
  navigationOpen,
}) => {
  const canOpen = open !== undefined;
  const classes = useStyles();

  return (
    <Requires
      role={role}
      notbrandup={notbrandup}
      notDeliveryOnly={notDeliveryOnly}
      notMultivendor={notMultivendor}
    >
      <ListItemButton
        className={hasStyle ? classes.customStyle : null}
        component={path && Link}
        to={path}
        selected={isRouteSelected(path)}
        onClick={canOpen ? handleOpen : navigationOpen}
      >
        {isBrandup ? (
          <ListItemIcon>
            <LockedIcon />
          </ListItemIcon>
        ) : (
          <ListItemIcon color="primary" sx={{ mr: 1, minWidth: 0 }}>
            {icon}
          </ListItemIcon>
        )}
        <ListItemText primaryTypographyProps={routeStyle(path)}>{title}</ListItemText>
        {canOpen && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
    </Requires>
  );
};

const NestedItem = ({
  path,
  title,
  altPath,
  isBrandup,
  role = 'staff',
  notbrandup = false,
  notDeliveryOnly = false,
  notMultivendor = false,
  isOnlyDelivery = false,
  navigationOpen,
}) => {
  const classes = useStyles();
  return (
    <Requires
      role={role}
      notbrandup={notbrandup}
      notDeliveryOnly={notDeliveryOnly}
      notMultivendor={notMultivendor}
      isOnlyDelivery={isOnlyDelivery}
    >
      <ListItemButton
        component={Link}
        to={path}
        className={classes.nested}
        selected={isRouteSelected(altPath) || isRouteSelected(path)}
        onClick={navigationOpen}
      >
        {isBrandup ? (
          <ListItemIcon>
            <LockedIcon />
          </ListItemIcon>
        ) : (
          <ListItemIcon />
        )}
        <ListItemText primaryTypographyProps={routeStyle(path, altPath)}>{title}</ListItemText>
      </ListItemButton>
    </Requires>
  );
};

const Navigation = ({ setting, venue, isNavigationOpen = true, navigationOpen }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { showErrorNotification } = useNotifications();
  const venuesState = useSelector(getVenuesState);
  const { loading, data: venues, error } = venuesState;
  const { showDeliveryReports, showSessionsScoreReports } = venue || {};
  const { data: appState } = useSelector((state) => state.app.session);
  const { username } = appState || {};
  const [openVenues, setOpenVenues] = useState(false);
  const [openOrders, setOpenOrders] = useState(false);
  const [openDiscounts, setOpenDiscounts] = useState(false);
  const [openReports, setOpenReports] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openProdcuts, setOpenProdcuts] = useState(false);
  const [openVenue, setOpenVenue] = useState(false);
  const [openBrandManager, setOpenBrandManager] = useState(true);
  const isBrandup = useSelector(isBrandUp);
  const history = useHistory();
  const venuePath = `${process.env.REACT_APP_WEBAPP_URI}/${venue.venuePath}`;
  const switchVenue = useSwitchVenue();
  const trackEvent = useEventTracker();

  const handleOpenAdmin = (e) => {
    e.stopPropagation();
    setOpenVenues(false);
    setOpenAdmin(!openAdmin);
  };
  const handleOpenVenues = (e) => {
    e.stopPropagation();
    setOpenVenues(!openVenues);
  };
  const handleOpenOrders = (e) => {
    e.stopPropagation();
    setOpenOrders(!openOrders);
  };
  const handleOpenDiscounts = (e) => {
    e.stopPropagation();
    setOpenDiscounts(!openDiscounts);
  };
  const handleOpenReports = (e) => {
    e.stopPropagation();
    setOpenReports(!openReports);
  };
  const handleOpenProdcuts = (e) => {
    e.stopPropagation();
    setOpenProdcuts(!openProdcuts);
  };
  const handleOpenVenue = (e) => {
    e.stopPropagation();
    setOpenVenue(!openVenue);
  };
  const handleOpenBrandManager = (e) => {
    e.stopPropagation();
    setOpenBrandManager(!openBrandManager);
  };
  const handleBackToVenues = (e) => {
    e.stopPropagation();
    history.push('/home');
  };
  const selectVenueById = async (venueId) => {
    try {
      setOpenVenues(false);
      switchVenue(venueId);
      dispatch(clearVenueSettings());
      dispatch(clearVenue());

      const venueState = await dispatch(fetchVenue());

      if (venueState) {
        trackEvent(username, 'venueDetails', venueState.venueId, venueState);
      }
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
    }
  };

  const checkOpenSections = useCallback(() => {
    const isAdminOpenNow = isSectionOpen(['/admin', '/images']);
    const isOrdersOpenNow = isSectionOpen(['/order', '/payouts', '/tabs']);
    const isDiscountsOpenNow = isSectionOpen([
      '/voucher',
      '/discounts/promo-codes',
      '/item-discounts',
      '/item-discount',
      '/offers',
      '/upsells',
    ]);
    const isProductsOpenNow = isSectionOpen([
      '/menus',
      '/menu-list',
      '/items',
      '/venues/personalisation',
    ]);
    const isVenueOpenNow = isSectionOpen([
      '/tables',
      '/sales-areas',
      '/devices',
      '/printers',
      '/mobiles',
      '/kiosks',
      '/staff',
      '/qrcodes',
      '/tills',
      '/accounts',
    ]);
    const isReportsOpenNow = isSectionOpen([
      '/reports/customers',
      '/reports/sales',
      '/reports/staff',
      '/reports/overview',
      '/reports/delivery-metrics',
      '/reports/sessions-score',
    ]);
    setOpenAdmin(isAdminOpenNow);
    setOpenOrders(isOrdersOpenNow);
    setOpenDiscounts(isDiscountsOpenNow);
    setOpenProdcuts(isProductsOpenNow);
    setOpenVenue(isVenueOpenNow);
    setOpenReports(isReportsOpenNow);
  }, []);

  useEffect(() => {
    const unlisten = history.listen(checkOpenSections);
    return unlisten;
  }, [checkOpenSections, history]);

  useEffect(() => checkOpenSections(), [checkOpenSections]);

  return (
    <>
      <FallbackProvider loading={loading} error={error}>
        {isRouteSelected('/brand-menu-management') ? (
          <Box className={classes.sideNavContainer}>
            <List className={classes.page} data-cy={cySelectors.NAVIGATION}>
              <AdminItem onClick={handleBackToVenues}>
                <ListItemIcon sx={{ mr: 1, minWidth: 0 }}>
                  <ChevronLeft style={{ color: '#FFF' }} />
                </ListItemIcon>
                <AdminItemText>Back to Venues</AdminItemText>
              </AdminItem>
              <Item
                title="Brand Management"
                icon={<FastfoodOutlined />}
                open={openBrandManager}
                handleOpen={handleOpenBrandManager}
              />
              <Collapse in={openBrandManager} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <NestedItem
                    path="/brand-menu-management/menus"
                    title="Brand Menus"
                    navigationOpen={navigationOpen}
                  />
                  <NestedItem
                    path="/brand-menu-management/items"
                    title="Brand Items"
                    navigationOpen={navigationOpen}
                  />
                </List>
              </Collapse>
            </List>
          </Box>
        ) : (
          <Box className={classes.sideNavContainer}>
            <List className={classes.page} data-cy={cySelectors.NAVIGATION}>
              {venues && venues.length > 1 && (
                <>
                  <Requires role={ROLES.ADMIN}>
                    <AdminItem onClick={handleOpenAdmin}>
                      <ListItemIcon sx={{ mr: 1, minWidth: 0 }}>
                        <span className="material-symbols-outlined" style={{ color: 'white' }}>
                          multiline_chart
                        </span>
                      </ListItemIcon>
                      <AdminItemText>Admin Area</AdminItemText>
                      {openAdmin ? <ExpandLess /> : <ExpandMore />}
                    </AdminItem>
                    <Collapse in={openAdmin} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding className={classes.navWithoutIcon}>
                        <NestedItem
                          path="/admin"
                          title="Settings"
                          navigationOpen={navigationOpen}
                        />
                        <NestedItem path="/images" title="Images" navigationOpen={navigationOpen} />
                        <NestedItem
                          path="/brand-menu-management/menus"
                          title="Brand Menu Management"
                          navigationOpen={navigationOpen}
                        />
                      </List>
                    </Collapse>
                  </Requires>
                  <ListItemButton onClick={handleOpenVenues}>
                    <ListItemText>Switch Venue</ListItemText>
                    {openVenues ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>

                  <Collapse in={openVenues} timeout={0}>
                    {openVenues && isNavigationOpen ? (
                      <>
                        <AutoComplete
                          addUnknownOff
                          disablePortal
                          label="Search"
                          options={venues.map((optionVenue) => ({
                            key: `${optionVenue.venueId}`,
                            nameCity: `${optionVenue.name}, ${optionVenue.city}`,
                            searchable: `${optionVenue.name}, ${optionVenue.city}, ${optionVenue.venueId}`,
                            ...optionVenue,
                          }))}
                          optionSearchKey="searchable"
                          optionLabelKey="nameCity"
                          optionValueKey="venueId"
                          onSelect={selectVenueById}
                          reset={openVenues}
                        />
                      </>
                    ) : null}
                  </Collapse>
                </>
              )}

              <Item
                path="/home"
                title="Home"
                icon={<DashboardOutlined />}
                navigationOpen={navigationOpen}
              />
              <Item
                title="Orders"
                icon={<ReceiptLongOutlined />}
                open={openOrders}
                handleOpen={handleOpenOrders}
              />
              <Collapse in={openOrders} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <NestedItem
                    path="/orders-history"
                    altPath="/orders/"
                    title="Order history"
                    navigationOpen={navigationOpen}
                  />
                  <NestedItem
                    path="/orders-live"
                    title="Live orders"
                    navigationOpen={navigationOpen}
                  />
                  <NestedItem
                    path="/orders-takeaway"
                    title="Takeaway orders"
                    notbrandup
                    notDeliveryOnly
                    notMultivendor
                    navigationOpen={navigationOpen}
                  />
                  <NestedItem
                    path="/payouts"
                    title="Payouts"
                    role={ROLES.MANAGER}
                    notDeliveryOnly
                    notMultivendor
                    navigationOpen={navigationOpen}
                  />
                  <NestedItem
                    path="/tabs"
                    title="Tabs"
                    isBrandup={isBrandup}
                    notDeliveryOnly
                    notMultivendor
                    navigationOpen={navigationOpen}
                  />
                </List>
              </Collapse>
              <Requires notbrandup notDeliveryOnly role={ROLES.STAFF}>
                <Item
                  title="Marketer"
                  role={ROLES.MANAGER}
                  icon={<LoyaltyOutlined />}
                  open={openDiscounts}
                  handleOpen={handleOpenDiscounts}
                />
                <Collapse in={openDiscounts} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <NestedItem
                      path="/offers"
                      title="Promotions"
                      role={ROLES.MANAGER}
                      notMultivendor
                      navigationOpen={navigationOpen}
                    />
                    <NestedItem path="/vouchers" title="Vouchers" navigationOpen={navigationOpen} />
                    <NestedItem
                      path="/discounts/promo-codes"
                      title="Promo codes"
                      navigationOpen={navigationOpen}
                    />
                    <NestedItem
                      path="/item-discounts"
                      title="Discounts"
                      notMultivendor
                      navigationOpen={navigationOpen}
                    />
                    <NestedItem
                      path="/upsells"
                      title="Upsells"
                      notMultivendor
                      navigationOpen={navigationOpen}
                    />
                  </List>
                </Collapse>
              </Requires>
              <Requires notbrandup notMultivendor role={ROLES.MANAGER}>
                <Item
                  title="Reports"
                  role={ROLES.MANAGER}
                  icon={<BarChartOutlined />}
                  open={openReports}
                  handleOpen={handleOpenReports}
                />
                <Collapse in={openReports} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <NestedItem
                      path="/reports/overview"
                      title="Overview"
                      navigationOpen={navigationOpen}
                    />
                    <Requires notstandard>
                      <NestedItem
                        path="/reports/sales"
                        title="Sales"
                        navigationOpen={navigationOpen}
                      />
                      <NestedItem
                        path="/reports/staff"
                        title="Team"
                        notDeliveryOnly
                        navigationOpen={navigationOpen}
                      />
                      <NestedItem
                        path="/reports/customers"
                        title="Customers"
                        notDeliveryOnly
                        navigationOpen={navigationOpen}
                      />
                      {showSessionsScoreReports && (
                        <NestedItem
                          path="/reports/sessions-score"
                          title="Sessions Score"
                          role={ROLES.MANAGER}
                          navigationOpen={navigationOpen}
                        />
                      )}
                      {showDeliveryReports && (
                        <NestedItem
                          path="/reports/delivery-metrics"
                          title="Delivery Metrics"
                          role={ROLES.MANAGER}
                          navigationOpen={navigationOpen}
                        />
                      )}
                    </Requires>
                  </List>
                </Collapse>
              </Requires>
              <Item
                notMultivendor
                title="Products"
                role={ROLES.MANAGER}
                icon={<FastfoodOutlined />}
                open={openProdcuts}
                handleOpen={handleOpenProdcuts}
              />
              <Collapse in={openProdcuts} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <NestedItem
                    path="/menus"
                    title="Menus"
                    standard
                    isBrandup={isBrandup}
                    navigationOpen={navigationOpen}
                  />
                  <NestedItem path="/items" title="Items" navigationOpen={navigationOpen} />
                  <NestedItem
                    path="/venues/personalisation"
                    title="Menu design"
                    notDeliveryOnly
                    navigationOpen={navigationOpen}
                  />
                </List>
              </Collapse>
              <Item
                title="Venue"
                role={ROLES.MANAGER}
                icon={<StorefrontOutlined />}
                open={openVenue}
                handleOpen={handleOpenVenue}
              />
              <Collapse in={openVenue} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <NestedItem
                    path="/tables"
                    title="Tables"
                    isBrandup={isBrandup}
                    notDeliveryOnly
                    navigationOpen={navigationOpen}
                  />
                  <Requires notstandard notDeliveryOnly>
                    <NestedItem
                      path="/sales-areas"
                      title="Areas"
                      notDeliveryOnly
                      notMultivendor
                      navigationOpen={navigationOpen}
                    />
                  </Requires>
                  <Requires notstandard role={ROLES.MANAGER}>
                    <NestedItem
                      path="/accounts"
                      title="Sales accounts"
                      notMultivendor
                      navigationOpen={navigationOpen}
                    />
                  </Requires>
                  <NestedItem
                    path="/devices"
                    title="Devices"
                    isBrandup={isBrandup}
                    notDeliveryOnly
                    notMultivendor
                    navigationOpen={navigationOpen}
                  />
                  <NestedItem
                    path="/mobiles"
                    title="Mobile devices"
                    isBrandup={isBrandup}
                    notDeliveryOnly
                    navigationOpen={navigationOpen}
                  />
                  {setting.KIOSK_ENABLED?.value && (
                    <Requires role={ROLES.MANAGER} notDeliveryOnly>
                      <NestedItem path="/kiosks" title="Kiosks" navigationOpen={navigationOpen} />
                    </Requires>
                  )}
                  <Requires role={ROLES.MANAGER} notDeliveryOnly>
                    <NestedItem
                      path="/staff"
                      title="Team"
                      isBrandup={isBrandup}
                      navigationOpen={navigationOpen}
                    />
                  </Requires>
                  <NestedItem
                    path="/qrcodes"
                    title="QR codes"
                    notDeliveryOnly
                    notMultivendor
                    navigationOpen={navigationOpen}
                  />
                  <NestedItem
                    path="/tills"
                    title="Tills"
                    notDeliveryOnly
                    notMultivendor
                    navigationOpen={navigationOpen}
                  />
                </List>
              </Collapse>
              <Item
                path="/customer-reviews"
                title="Customer reviews"
                role={ROLES.MANAGER}
                isBrandup={isBrandup}
                icon={<MoodOutlined />}
                navigationOpen={navigationOpen}
              />
            </List>
            <Box className={classes.settingContainer}>
              <QrContainer title="View Menu" value={venuePath} isLink isNavigation />
              <Item
                icon={<SettingsOutlined />}
                path="/settings"
                title="Settings"
                role={ROLES.MANAGER}
                navigationOpen={navigationOpen}
              />
            </Box>
          </Box>
        )}
      </FallbackProvider>
    </>
  );
};

const NavigationWithVenue = withVenue(Navigation, null, clearVenue);

export default withSettings(NavigationWithVenue, ['KIOSK_ENABLED']);
